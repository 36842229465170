import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Group } from '@models/group/group';
import { Role } from '@models/role/role';
import { AdminComponent } from '@pages/admin/admin.component';
import { AlumniComponent } from '@pages/analyze/alumni/alumni.component';
import { AnalyzeComponent } from '@pages/analyze/analyze.component';
import { ObservatoireComponent } from '@pages/analyze/observatoire/observatoire.component';
import { ReportsComponent } from '@pages/analyze/reports/reports.component';
import { TypicalPathComponent } from '@pages/analyze/typical-path/typical-path.component';
import { TypicalPathGuard } from '@pages/analyze/typical-path/typical-path.guard';
import { HomeComponent } from '@pages/home/home.component';
import { HiddenProfilesComponent } from '@pages/iframe/admin/hidden-profiles/hidden-profiles.component';
import { NewUserComponent } from '@pages/iframe/admin/new-user/new-user.component';
import { UserManagementComponent } from '@pages/iframe/admin/user-management/user-management.component';
import { BenchmarkIframeComponent } from '@pages/iframe/analyser/benchmark/benchmarkIframe.component';
import { AnnuaireIframeComponent } from '@pages/iframe/analyser/data/annuaire/annuaireIframe.component';
import { ProfileComponent } from '@pages/iframe/analyser/data/profile/profile.component';
import { CompanyComponent } from '@pages/iframe/analyser/observatoire/company/company.component';
import { MetierComponent } from '@pages/iframe/analyser/observatoire/metier/metier.component';
import { PathwaysComponent } from '@pages/iframe/analyser/pathways/pathways.component';
import { SurveyIframeComponent } from '@pages/iframe/analyser/survey/surveyIframe.component';
import { ConnectDashboardComponent } from '@pages/iframe/connect-dashboard/connect-dashboard.component';
import { MyroadComponent } from '@pages/myroad/myroad.component';
import { AuthGuard } from './guards/auth.guard';
import { ErrorComponent } from './shared/components/pages/error.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Home',
    },
  },
  {
    path: 'error/:code',
    component: ErrorComponent,
  },
  {
    path: 'myroad',
    data: {
      title: 'myroad',
      groups: [Group.MyRoad],
    },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        component: MyroadComponent,
      },
      {
        path: 'dashboard',
        component: ConnectDashboardComponent,
        data: {
          title: 'Dashboard',
        },
      },
    ],
  },
  {
    path: 'admin',
    data: {
      title: 'admin',
      roles: [Role.AppAdmin],
    },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        component: AdminComponent,
      },
      {
        path: 'gestion',
        component: UserManagementComponent,
        data: {
          title: 'gestion',
        },
      },
      {
        path: 'newUser',
        component: NewUserComponent,
        data: {
          title: 'newUser',
        },
      },
      {
        path: 'hiddenProfiles',
        component: HiddenProfilesComponent,
        data: {
          title: 'hiddenProfiles',
        },
      },
    ],
  },
  {
    path: 'analyser',
    data: {
      groups: [Group.Analyser],
      roles: [Role.AppManager, Role.AppAdmin],
    },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        component: AnalyzeComponent,
        data: {
          title: 'analyser',
          label: 'Analyser',
        },
      },
      {
        path: 'alumni',
        children: [
          {
            path: '',
            component: AlumniComponent,
          },
          {
            path: 'annuaire',
            component: AnnuaireIframeComponent,
            data: {
              title: 'Annuaire',
            },
          },
          {
            path: 'profil',
            component: ProfileComponent,
            data: {
              title: 'Profil',
            },
          },
        ],
      },
      {
        path: 'observatoire',
        children: [
          {
            path: '',
            component: ObservatoireComponent,
          },
          {
            path: 'metier',
            component: MetierComponent,
          },
          {
            path: 'company',
            component: CompanyComponent,
          },
        ],
      },
      {
        path: 'reports',
        children: [
          {
            path: '',
            component: ReportsComponent,
          },
          {
            path: 'typical-path',
            component: TypicalPathComponent,
            canActivate: [TypicalPathGuard],
          },
          {
            path: 'pathways',
            component: PathwaysComponent,
          },
          {
            path: 'benchmark',
            component: BenchmarkIframeComponent,
            data: {
              title: 'Benchmark',
            },
          },
          {
            path: 'survey',
            component: SurveyIframeComponent,
            data: {
              title: 'Survey',
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
