import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from './error.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule, Routes } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

const routes: Routes = [
  {
    path: '',
    component: ErrorComponent,
  },
];

@NgModule({
  declarations: [ErrorComponent],
  exports: [ErrorComponent],
  imports: [
    // External Modules
    CommonModule,
    FlexLayoutModule,
    RouterModule.forChild(routes),
    TranslateModule,
  ],
})
export class ErrorModule {}
