<div fxLayout="column wrap">
  <div class="p-32">
    <div class="mat-h1 bold">{{ 'home.welcome.title' | translate }}</div>
  </div>
  <ng-container *ngIf="application$ | async as application; else selectApplication">
    <div
      fxFlex
      fxLayoutAlign="center stretch"
      fxLayoutAlign.gt-xs="start stretch"
      fxLayout="row wrap"
      fxLayoutGap="10px grid"
    >
      <app-card
        [fxFlexOrder]="(hasAnalyzeRight$ | async) === false && 5"
        fxFlex="25%"
        fxFlex.xs="80%"
        [isDisplay]="true"
        (click)="click$.next(ClickEnum.ANALYZE)"
        [buyed]="hasAnalyzeRight$ | async"
        [image]="'assets/img/solutions/analyze.svg'"
        [imageTitleKey]="'solutions.analyze'"
        [desc]="'solutions.analyze-desc' | translate"
      >
      </app-card>
      <app-card
        [fxFlexOrder]="(hasMyRoadRight$ | async) === false && 5"
        fxFlex="25%"
        fxFlex.xs="80%"
        [isDisplay]="true"
        (click)="click$.next(ClickEnum.MYROAD)"
        [buyed]="hasMyRoadRight$ | async"
        [image]="'assets/img/solutions/myroad.svg'"
        [imageTitleKey]="'solutions.my-road'"
        [desc]="'solutions.my-road-desc' | translate"
      >
      </app-card>
      <app-card
        [fxFlexOrder]="(hasAdminRight$ | async) === false && 5"
        fxFlex="25%"
        fxFlex.xs="80%"
        [isDisplay]="hasAdminRight$ | async"
        (click)="click$.next(ClickEnum.ADMIN)"
        [desc]="'home.admin.desc' | translate"
        [icon]="'⚙️'"
        label="{{ 'home.admin.title' | translate }}"
      >
      </app-card>
    </div>
  </ng-container>
</div>

<ng-template #selectApplication>
  <mat-form-field *ngIf="isManager" class="search">
    <mat-label>{{ 'search' | translate }}</mat-label>
    <input matInput [(ngModel)]="searchText" (focus)="onSearch()" />
  </mat-form-field>
  <ng-container
    *ngIf="applications$ | async as applications"
    [ngTemplateOutlet]="isManager ? tabApplicationList : applicationList"
    [ngTemplateOutletContext]="{ applications: applications }"
  >
  </ng-container>
</ng-template>

<!-- List of Application with tab for Admin -->
<ng-template #tabApplicationList let-applications="applications">
  <mat-tab-group #tabGroup mat-align-tabs="center">
    <ng-container *ngFor="let tab of tabs">
      <mat-tab [label]="tab.label | translate">
        <ng-container
          [ngTemplateOutlet]="applicationList"
          [ngTemplateOutletContext]="{
            applications: tab.filter(applications | search: 'application,name':searchText)
          }"
        >
        </ng-container>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</ng-template>

<!-- List of Application Card -->
<ng-template #applicationList let-applications="applications">
  <div fxLayout="row wrap" fxLayout.xs="column wrap">
    <app-card
      *ngFor="let app of applications"
      fxFlex="25%"
      fxFlex.xs="80%"
      (click)="onSelectedApplication(app)"
      [image]="app?.logo || null"
      [imageTitleKey]="app.name"
      [imageTitleClass]="'mat-title'"
    >
    </app-card>
    <span *ngIf="applications.length === 0" class="mt-24 hidden">{{ 'no-results' | translate }}</span>
  </div>
</ng-template>
