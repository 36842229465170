import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() label: string;
  @Input() desc: string;
  @Input() image: string;
  @Input() imageTitleKey: string;
  @Input() imageTitleClass = 'mat-display-1';
  @Input() isDisplay = true;
  @Input() icon: string;
  @Input() isLab = false;
  @Input() buyed = true;
  @Input() knowMore = false;
  @Input() contact = false;

  constructor(private translate: TranslateService) {}

  public getButtonText() {
    if (this.contact) return this.translate.instant('contact-us');
    else if (this.knowMore) return this.translate.instant('know-more');
    return this.translate.instant('continuer');
  }
}
