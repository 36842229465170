<div class="item">
  <div class="left" [ngClass]="{ 'pt-6': size === sizeEnum.headline }">
    <div class="image">
      <img [src]="iconUrl" />
    </div>
    <div *ngIf="!noDash" class="dash"></div>
  </div>
  <div>
    <div
      class="ellipsis-2-lines"
      [ngClass]="{
        'mat-display-1': size === sizeEnum.display,
        'mat-headline': size === sizeEnum.headline,
        'mat-title': size === sizeEnum.title
      }"
    >
      {{ label }}
    </div>
    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
