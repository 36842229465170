import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TypicalPathService {
  paths$: Observable<any>;

  constructor(private httpClient: HttpClient) {
    this.paths$ = this.httpClient.get('assets/paths.json').pipe(map((json) => this.transform(json)));
  }

  private transform(json: any) {
    return Object.entries(json).map(([label, data]) => {
      const [profil, apres, pendant, avant] = Object.entries(data);

      return {
        label: apres[1].Emploi,
        data: [apres, pendant, avant, profil].map(([label1, data1], index) => ({
          label: index === 1 ? data1.Formation : label1.trim(),
          iconUrl: this.getIconUrl(index),
          data: Object.entries(data1).map(([label2, data2]) => ({
            label: this.getEmoji(label2.trim()) + label2.trim(),
            data: data2,
          })),
        })),
      };
    });
  }

  private getIconUrl(index: number) {
    switch (index) {
      case 0:
        return 'assets/img/marker-end.svg';
      case 1:
        return 'assets/img/marker.svg';
      case 2:
        return 'assets/img/circle.svg';
      case 3:
        return 'assets/img/profil.svg';
      default:
        return '';
    }
  }

  private getEmoji(label: string) {
    switch (label) {
      case 'Durée de la formation':
        return '⏱ ';
      case 'Top formacodes':
        return '📚 ';
      case 'Position':
        return '🔢 ';
      case 'Nombre de candidatures':
        return '👀 ';
      case "Nombre d'entretiens":
        return '👥 ';
      case 'QPV':
        return '🏢 ';
      case 'Moyen de locomotion':
        return '🚌 ';
      case 'Précarité':
        return '⌛️ ';
      case 'RSA':
        return '💶 ';
      case 'Handicap':
        return '👨‍🦽 ';
      case 'Indemnité chômage':
        return '💶 ';
      case 'Genre':
        return '🚺/🚹 ';
      case 'Domaines de compétences':
        return '💪 ';
      case 'Métiers recherchés':
        return '🔍 ';
      case 'Job domaine':
        return '💼 ';
      case "Type de demandeur d'emploi":
        return '👁 ';
      case 'Disponibilité':
        return '📅 ';
      case 'Axe de formation':
        return '⛪️ ';
      case "Nombre d'abandon":
        return '🙅 ';
      case 'Age':
        return '🎂 ';
      case 'Mobilité':
        return '🚦';
      case 'Enfants à charge':
        return '👨‍👩‍👧‍👧 ';
      case 'Modalité de suivi':
        return '🆘 ';
      case 'Dynamique des candidatures':
        return '💎 ';
      case 'Contrat de travail':
        return '🤝';
      case 'Inscrit dans un cycle de formation':
        return '🔢';
      default:
        return '';
    }
  }
}
