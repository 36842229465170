import { Component } from '@angular/core';
import { ApplicationService } from '@services/application.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-myroad',
  templateUrl: './myroad.component.html',
  styleUrls: ['./myroad.component.scss'],
})
export class MyroadComponent {
  public applicationField$: Observable<string>;

  constructor(private applicationService: ApplicationService) {
    this.applicationField$ = this.applicationService.applicationField$;
  }

  public connectV2Redirect(applicationField: string): void {
    window.open('https://' + applicationField + '.connect.millionroads.com', '_blank');
  }

  public connectV1Redirect(applicationField: string): void {
    window.open('https://' + applicationField + '.humanroads.com', '_blank');
  }
}
