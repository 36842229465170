import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-path-item-detail',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './path-item-detail.component.html',
  styleUrls: ['./path-item-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PathItemDetailComponent {
  @Input() item: any;
  @Input() avantCard = false;

  isCity(label1: string, label2: string) {
    return label1.includes(label2.trim());
  }

  isArray(value: any) {
    return Array.isArray(value);
  }
}
