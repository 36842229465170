import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { Application, Status } from '@models/application/application';
import { Group } from '@models/group/group';
import { Role } from '@models/role/role';
import { ApplicationService } from '@services/application.service';
import { AuthService } from 'app/auth/auth.service';
import { merge, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

interface Tab {
  label: string;
  filter: (applications: Application[]) => Application[];
}

enum ClickEnum {
  ADMIN = 'admin',
  ANALYZE = 'analyze',
  MYROAD = 'myroad',
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: [
    `
      .hidden {
        overflow: hidden;
      }
      .search {
        margin-right: 32px;
        place-self: flex-end;
        width: 25%;
      }
    `,
  ],
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('tabGroup') public tabGroup: MatTabGroup;

  public tabs: Tab[];
  public searchText = '';
  public applications$: Observable<Application[]>;
  public application$: Observable<Application>;
  public isManager: boolean;
  public readonly ClickEnum = ClickEnum;

  public hasAdminRight$: Observable<boolean>;
  public hasAnalyzeRight$: Observable<boolean>;
  public hasMyRoadRight$: Observable<boolean>;

  public click$ = new Subject<string>();
  private destroy$ = new Subject<void>();

  constructor(
    private applicationService: ApplicationService,
    private authService: AuthService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.applications$ = this.authService.applications$;
    this.application$ = this.applicationService.application$;
    this.isManager = this.authService.user.isManager;

    this.tabs = this.getTabs();

    this.setApplicationRights();

    this.click$
      .pipe(
        switchMap((type: ClickEnum) => this.goRoute(type)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSelectedApplication(application: Application): void {
    this.applicationService.updateApplication(application);
  }

  public onSearch(): void {
    (this.tabGroup.selectedIndex as number) +=
      this.tabGroup._tabs.toArray()[this.tabGroup._tabs.toArray().length - 1].position;
  }

  private getTabs(): Tab[] {
    return [
      {
        label: 'home.app-selection.in-progress',
        filter: (applications: Application[]) => applications.filter((app) => Status.en_cours === app.status),
      },
      {
        label: 'home.app-selection.delivered',
        filter: (applications: Application[]) => applications.filter((app) => Status.livre === app.status),
      },
      {
        label: 'home.app-selection.demo',
        filter: (applications: Application[]) => applications.filter((app) => Status.demo === app.status),
      },
      {
        label: 'home.app-selection.backlog',
        filter: (applications: Application[]) => applications.filter((app) => Status.backlog === app.status),
      },
      {
        label: 'home.app-selection.renewal',
        filter: (applications: Application[]) => applications.filter((app) => Status.renouvellement === app.status),
      },
      {
        label: 'home.app-selection.not-renewed',
        filter: (applications: Application[]) => applications.filter((app) => Status.non_renouvele === app.status),
      },
      {
        label: 'home.app-selection.no-status',
        filter: (applications: Application[]) => applications.filter((app) => !app.status),
      },
      {
        label: 'home.app-selection.all',
        filter: (applications: Application[]) => applications,
      },
    ];
  }

  private setApplicationRights(): void {
    const { user, isAuthenticated } = this.authService;

    const userHasAccess = (application: Application) =>
      isAuthenticated && user && application?.status !== Status.non_renouvele;

    this.hasAdminRight$ = this.application$.pipe(
      map((application) => user.isManager || (userHasAccess(application) && user.roles.includes(Role.AppAdmin)))
    );

    this.hasAnalyzeRight$ = this.application$.pipe(
      map(
        (application) =>
          user.isManager ||
          (userHasAccess(application) && user.roles.includes(Role.AppManager) && user.groups.includes(Group.Analyser))
      )
    );

    this.hasMyRoadRight$ = this.application$.pipe(
      map((application) => user.isManager || (userHasAccess(application) && user.groups.includes(Group.MyRoad)))
    );
  }

  private goRoute(value: ClickEnum): Observable<boolean> {
    if (value === ClickEnum.ADMIN) {
      return this.hasAdminRight$.pipe(
        take(1),
        filter((h) => h),
        tap(() => this.router.navigate(['/admin']))
      );
    }

    if (value === ClickEnum.ANALYZE) {
      return merge(
        this.hasAnalyzeRight$.pipe(
          filter((h) => h),
          tap(() => this.router.navigate(['/analyser']))
        ),
        this.hasAnalyzeRight$.pipe(
          filter((h) => !h),
          tap(() => window.open('https://www.millionroads.com/analyze', '_blank'))
        )
      ).pipe(take(1));
    }

    if (value === ClickEnum.MYROAD) {
      return merge(
        this.hasMyRoadRight$.pipe(
          filter((h) => h),
          tap(() => this.router.navigate(['/myroad']))
        ),
        this.hasMyRoadRight$.pipe(
          filter((h) => !h),
          tap(() => window.open('https://join.myroad.app/', '_blank'))
        )
      ).pipe(take(1));
    }
  }
}
