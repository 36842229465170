<mat-card class="clickable-card small-padding-card" (click)="onClick($event, path)">
  <ng-container *ngFor="let data of path.data; index as i">
    <app-path-item
      *ngIf="i < 3"
      [size]="i === 0 ? 'headline' : 'title'"
      [label]="i === 0 ? path.label : i === 2 ? data.data[0].label : data.label"
      [iconUrl]="data.iconUrl"
      [noDash]="i === 2"
    >
      <app-path-item-detail *ngIf="i === 2" [item]="data" [avantCard]="true"></app-path-item-detail>
    </app-path-item>
  </ng-container>

  <button mat-button (click)="onClick($event, path)">Voir le détail</button>
</mat-card>
