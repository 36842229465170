<ng-container *ngIf="!avantCard; else avant">
  <ul class="level-one">
    <ng-container *ngIf="item[0].label === 'Métiers avant'">
      <ng-container *ngFor="let j of item[0].data">
        <li>
          <div class="mat-title text">{{ j }}</div>
        </li>
      </ng-container>
    </ng-container>
    <li *ngFor="let i of item">
      <ng-container *ngIf="i.label !== 'Emploi' && i.label !== 'Formation' && i.label !== 'Métiers avant'">
        <ng-container *ngIf="i.label === 'Departement'; else normal">
          <div class="mat-title text">{{ '📍 ' + i.data }}</div>
        </ng-container>

        <ng-template #normal>
          <ng-container *ngIf="i.label === 'Métiers avant'"> </ng-container>
          <ng-container *ngIf="i.data.length || i.data === 1">
            <div class="mat-title text">{{ i.label }}</div>
            <ul *ngIf="i.data.length" class="level-two">
              <ng-container *ngIf="isArray(i.data); else notArray">
                <ng-container *ngFor="let j of i.data">
                  <li>
                    <div class="text">{{ j }}</div>
                  </li>
                </ng-container>
              </ng-container>
              <ng-template #notArray>
                <li>
                  <div class="text">{{ i.data }}</div>
                </li>
              </ng-template>
            </ul>
          </ng-container>
        </ng-template>
      </ng-container>
    </li>
  </ul>
</ng-container>
<ng-template #avant>
  <ul class="level-one list-circle">
    <ng-container *ngFor="let i of item.data[0].data">
      <li>
        <div class="text">{{ i }}</div>
      </li>
    </ng-container>
  </ul>
</ng-template>
