import { Component } from '@angular/core';
import { IframeComponent } from '@atoms/iframe/iframe.component';

@Component({
  selector: 'app-benchmarkIframe',
  templateUrl: './benchmarkIframe.component.html',
})
export class BenchmarkIframeComponent extends IframeComponent {
  public name() {
    return '/benchmark/';
  }
}
