import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@models/user/user';
import { ApplicationService } from '@services/application.service';
import { Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

const TRACKING_URI = '/apis/tracking';

interface Action {
  uuid: string;
  product: string;
  application: string;
  type: string;
}

/**
 * Client responsible of actions tracking
 */
@Injectable({ providedIn: 'root' })
export class TrackingClient {
  constructor(private http: HttpClient, private applicationService: ApplicationService) {}

  /**
   * Send tracking action to save
   *
   * @param user The connected user
   * @returns The action saved
   */
  public actions(user: User): Observable<Action> {
    return this.applicationService.application$.pipe(
      filter((application) => !!application),
      take(1),
      switchMap(({ application }) => {
        return this.http.post<Action>(`${TRACKING_URI}/actions`, {
          uuid: user.uuid,
          product: 'ANALYZE',
          application,
          type: 'LOGGED',
        });
      })
    );
  }
}
