import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ApplicationService } from '@services/application.service';
import { Observable, map } from 'rxjs';

export const poleEmploiApplication = 'pric-paca';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportsComponent {
  isPoleEmploiApplication$: Observable<boolean>;

  constructor(private applicationService: ApplicationService) {
    this.isPoleEmploiApplication$ = this.applicationService.application$.pipe(
      map((app) => app.application === poleEmploiApplication)
    );
  }
}
