import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'environments/environment';

/**
 * Configuration for the OAuth module
 */
export const authConfig: AuthConfig = {
  /**
   * Keycloak configuration
   */
  issuer: environment.keycloak.issuer,
  redirectUri: environment.keycloak.redirectUri,
  silentRefreshRedirectUri: `${environment.keycloak.redirectUri}${environment.keycloak.silentRefreshPage}`,
  clientId: environment.keycloak.clientId,

  /**
   * Use Code Flow, the last OIDC best practice
   */
  responseType: 'code',

  // FIXME : To get a refresh token for silent refresh with Code Flow, offline_access should be added but we get an error from Keycloak
  // scope: 'openid profile email offline_access',
  scope: 'openid profile email',

  /**
   * Needed to use silent refresh with Code Flow
   */
  useSilentRefresh: true,

  /**
   * Periodically checks the validity of the session
   */
  sessionChecksEnabled: true,
  sessionCheckIntervall: 10000,

  /**
   * https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457
   */
  clearHashAfterLogin: false,

  showDebugInformation: environment.keycloak.showDebugInformation,

  /**
   * Has to be set to true as Keycloak does not return at_hash claim in access token.
   * https://ordina-jworks.github.io/security/2019/08/22/Securing-Web-Applications-With-Keycloak.html
   */
  disableAtHashCheck: true,
};
