<div class="wrapper">
  <h1 class="mat-display-1 mb-16">🏆 Parcours gagnants</h1>
  <section class="description">
    <p>
      Les parcours gagnants mettent en avant des parcours permettant de retrouver un emploi 6 mois après sa formation.
    </p>
    <a
      href="https://doc.millionroads.com/M-thodologie-des-Parcours-Gagnants-236cec4e273242ee963baeec0bd22b89"
      target="_blank"
      class="blue"
      >Détail de la méthodologie</a
    >
    <mat-icon class="blue icon ml-8">launch</mat-icon>
  </section>

  <section class="selectors">
    <h1 class="mb-16">Choix du parcours</h1>
    <p>Type de parcours</p>
    <button class="selector" mat-flat-button [matMenuTriggerFor]="parcoursMenu" color="grey">
      {{ pathType || 'Type de parcours' }}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #parcoursMenu="matMenu" class="mat-menu-panel-custom">
      <a (click)="onSelectPathType(PathType.JOB)" mat-menu-item>
        <span class="mat-h2">{{ PathType.JOB }}</span>
      </a>
      <mat-divider></mat-divider>
      <a (click)="onSelectPathType(PathType.DIPLOMA)" mat-menu-item>
        <span class="mat-h2">{{ PathType.DIPLOMA }}</span>
      </a>
      <mat-divider></mat-divider>
      <a (click)="onSelectPathType(PathType.JOB_BEFORE)" mat-menu-item>
        <span class="mat-h2">{{ PathType.JOB_BEFORE }}</span>
      </a>
    </mat-menu>

    <ng-container *ngIf="pathType === PathType.JOB && emplois$ | async as emplois">
      <p *ngIf="pathType" class="mt-16">Emploi</p>
      <button class="selector" mat-flat-button [matMenuTriggerFor]="emploiMenu" color="grey">
        {{ emploi || 'Emploi' }}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #emploiMenu="matMenu" class="mat-menu-panel-custom">
        <ng-container *ngFor="let emploi of emplois; let first = first">
          <mat-divider *ngIf="!first"></mat-divider>
          <a (click)="onSelectEmplois(emploi)" mat-menu-item>
            <span class="mat-h2">{{ emploi }}</span>
          </a>
        </ng-container>
      </mat-menu>
    </ng-container>

    <ng-container *ngIf="pathType === PathType.DIPLOMA && formations$ | async as formations">
      <p *ngIf="pathType" class="mt-16">Formation</p>
      <button class="selector" mat-flat-button [matMenuTriggerFor]="formationMenu" color="grey">
        {{ formation || 'Formation' }}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #formationMenu="matMenu" class="mat-menu-panel-custom">
        <ng-container *ngFor="let formation of formations; let first = first">
          <mat-divider *ngIf="!first"></mat-divider>
          <a (click)="onSelectFormation(formation)" mat-menu-item>
            <span class="mat-h2">{{ formation }}</span>
          </a>
        </ng-container>
      </mat-menu>
    </ng-container>

    <ng-container *ngIf="pathType === PathType.JOB_BEFORE && emploisBefore$ | async as emploisBefore">
      <p *ngIf="pathType" class="mt-16">Emploi</p>
      <button class="selector" mat-flat-button [matMenuTriggerFor]="formationMenu" color="grey">
        {{ emploiBefore || 'Emploi' }}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #formationMenu="matMenu" class="mat-menu-panel-custom">
        <ng-container *ngFor="let emploiBefore of emploisBefore; let first = first">
          <mat-divider *ngIf="!first"></mat-divider>
          <a (click)="onSelectEmploisBefore(emploiBefore)" mat-menu-item>
            <span class="mat-h2">{{ emploiBefore }}</span>
          </a>
        </ng-container>
      </mat-menu>
    </ng-container>
  </section>

  <section class="categories">
    <ng-container *ngIf="selectedPaths$ | async as paths">
      <app-path-card *ngFor="let path of paths" [path]="path"></app-path-card>
    </ng-container>
  </section>
</div>
