import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'environments/environment';
import { ApplicationService } from '@services/application.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-metier',
  templateUrl: './metier.component.html',
})
export class MetierComponent implements OnInit {
  public link$: Observable<SafeResourceUrl>;

  constructor(
    private oauthService: OAuthService,
    private applicationService: ApplicationService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer
  ) {}

  // -- LIFE CYCLE --

  public ngOnInit(): void {
    this.link$ = this.applicationService.applicationField$.pipe(
      map((applicationField) => this.buildSrc(applicationField)),
      map((src) => this.sanitizer.bypassSecurityTrustResourceUrl(src))
    );
  }

  private buildSrc(applicationField: string): string {
    return (
      environment.iframeBaseUrl +
      '/' +
      this.translateService.currentLang +
      '/data/' +
      applicationField +
      '?token=' +
      this.oauthService.getAccessToken() +
      '&tab=data-group-jobs'
    );
  }
}
