<nav mat-tab-nav-bar mat-align-tabs="start" class="bg-white">
  <a [routerLink]="'/'" *ngIf="getCurrentRoute('home')">
    <img class="header-logo" src="/assets/img/logo.svg" />
  </a>
  <span *ngIf="!getCurrentRoute('home')" (click)="onBack()" class="material-icons pointer m-18 ml-24">
    arrow_back
  </span>
  <ng-container *ngFor="let image of images">
    <a *ngIf="getCurrentRoute(image.id)" mat-tab-link style="height: 60px" [routerLink]="image.id" class="opacity">
      <img *ngIf="image.path" class="header-icon mr-8" [src]="image.path" />
      <span class="mat-h2 bold">{{ image.labelKey | translate }}</span>
    </a>
  </ng-container>

  <span class="space"></span>
  <app-application-menu></app-application-menu>
  <app-profile-menu></app-profile-menu>
</nav>
