import { Component } from '@angular/core';
import { IframeComponent } from '@atoms/iframe/iframe.component';

@Component({
  selector: 'app-surveyIframe',
  templateUrl: './surveyIframe.component.html',
})
export class SurveyIframeComponent extends IframeComponent {
  public name() {
    return '/survey/';
  }
}
