<div
  fxFlex
  fxLayoutAlign="center stretch"
  fxLayoutAlign.gt-xs="start stretch"
  fxLayout="row wrap"
  fxLayoutGap="10px grid"
>
  <app-card
    *ngIf="isPoleEmploiApplication$ | async"
    fxFlex="33%"
    fxFlex.xs="80%"
    icon="🏆"
    routerLink="typical-path"
    label="Parcours gagnants"
    desc="Détecter les facteurs qui caractérisent les parcours d’insertion professionnelle"
  >
  </app-card>
  <app-card
    fxFlex="33%"
    fxFlex.xs="80%"
    icon="📈"
    routerLink="pathways"
    [label]="'analyze.reports.pathways.title' | translate"
    [desc]="'analyze.reports.pathways.desc' | translate"
  >
  </app-card>
  <app-card
    fxFlex="33%"
    fxFlex.xs="80%"
    icon="📊"
    routerLink="benchmark"
    [label]="'analyze.reports.benchmark.title' | translate"
    [desc]="'analyze.reports.benchmark.desc' | translate"
  >
  </app-card>
  <ng-container *ngIf="(isPoleEmploiApplication$ | async) === false">
    <app-card
      fxFlex="33%"
      fxFlex.xs="80%"
      icon="🔍"
      routerLink="survey"
      [label]="'analyze.reports.enquete.title' | translate"
      [desc]="'analyze.reports.enquete.desc' | translate"
    >
    </app-card>
    <a
      fxFlex="33%"
      fxFlex.xs="80%"
      [href]="'analyze.alumni.cohort.verified.link' | translate"
      target="_blank"
      style="text-decoration: none"
    >
      <app-card
        icon="✔️"
        [label]="'analyze.alumni.cohort.verified.title' | translate"
        [knowMore]="true"
        [desc]="'analyze.alumni.cohort.verified.desc' | translate"
      >
      </app-card>
    </a>
  </ng-container>
</div>
