import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserManagementComponent } from './user-management.component';

@NgModule({
  declarations: [UserManagementComponent],
  imports: [CommonModule],
  exports: [UserManagementComponent],
})
export class UserManagementModule {}
