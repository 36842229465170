import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationMenuComponent } from './application-menu.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ApplicationMenuComponent],
  exports: [ApplicationMenuComponent],
  imports: [
    // External Modules
    CommonModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    TranslateModule,
  ],
})
export class ApplicationMenuModule {}
