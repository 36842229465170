import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from '@services/application.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-iframe',
  template: ``,
})
export abstract class IframeComponent implements OnInit, OnDestroy {
  public link$: Observable<SafeResourceUrl>;
  private onDestroySubject$ = new Subject<void>();

  constructor(
    private oauthService: OAuthService,
    private applicationService: ApplicationService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer
  ) {}

  // -- LIFE CYCLE --

  public ngOnInit() {
    this.loadLink();

    this.translateService.onLangChange.pipe(takeUntil(this.onDestroySubject$)).subscribe(() => this.loadLink());
  }

  public ngOnDestroy(): void {
    this.onDestroySubject$.next();
    this.onDestroySubject$.complete();
  }

  // -- ABSTRACT --

  public abstract name();

  // -- PRIVATE --

  private loadLink(): void {
    this.link$ = this.applicationService.applicationField$.pipe(
      map((applicationField) => this.buildSrc(applicationField)),
      map((src) => this.sanitizer.bypassSecurityTrustResourceUrl(src))
    );
  }

  private buildSrc(applicationField: string): string {
    return (
      environment.iframeBaseUrl +
      '/' +
      this.translateService.currentLang +
      this.name() +
      applicationField +
      '?token=' +
      this.oauthService.getAccessToken()
    );
  }
}
