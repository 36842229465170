import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderModule } from '@molecules/header/header.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AdminModule } from '@pages/admin/admin.module';
import { AnalyzeModule } from '@pages/analyze/analyze.module';
import { HomeModule } from '@pages/home/home.module';
import { HiddenProfilesModule } from '@pages/iframe/admin/hidden-profiles/hidden-profiles.module';
import { NewUserModule } from '@pages/iframe/admin/new-user/new-user.module';
import { UserManagementModule } from '@pages/iframe/admin/user-management/user-management.module';
import { BenchmarkIframeModule } from '@pages/iframe/analyser/benchmark/benchmarkIframe.module';
import { AnnuaireIframeModule } from '@pages/iframe/analyser/data/annuaire/annuaireIframe.module';
import { ProfileModule } from '@pages/iframe/analyser/data/profile/profile.module';
import { CompanyModule } from '@pages/iframe/analyser/observatoire/company/company.module';
import { MetierModule } from '@pages/iframe/analyser/observatoire/metier/metier.module';
import { PathwaysModule } from '@pages/iframe/analyser/pathways/pathways.module';
import { ProfessionnelModule } from '@pages/iframe/analyser/professionnel/professionnel.module';
import { ScolaireModule } from '@pages/iframe/analyser/scolaire/scolaire.module';
import { SurveyIframeModule } from '@pages/iframe/analyser/survey/surveyIframe.module';
import { ConnectDashboardModule } from '@pages/iframe/connect-dashboard/connect-dashboard.module';
import { MyroadModule } from '@pages/myroad/myroad.module';
import { initApp } from '@services/app-pre-boot-initializer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AuthService } from './auth/auth.service';
import { ErrorModule } from './shared/components/pages/error.module';

const createTranslateLoader = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http);

class DynamicLocaleId extends String {
  constructor(protected service: TranslateService) {
    super();
  }
}

/**
 * waits until translations are loaded
 * see https://github.com/ngx-translate/core/issues/517#issuecomment-299637956
 */
const appInitializerFactory = (translate: TranslateService, injector: Injector) => (): Promise<any> =>
  new Promise<any>((resolve: any) => {
    registerLocaleData(localeFr);
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

    locationInitialized.then(() => {
      translate.addLangs(['en', 'fr']);
      translate.setDefaultLang('en');
      const lang = translate.getBrowserLang();

      translate.use(translate.getLangs().includes(lang) ? lang : translate.defaultLang).subscribe(
        () => {
          console.log(`Successfully initialized '${lang}' language.'`);
        },
        () => {
          console.error(`Problem with '${lang}' language initialization.'`);
        },
        () => {
          resolve(null);
        }
      );
    });
  });

@NgModule({
  declarations: [AppComponent],
  imports: [
    // External Module
    BrowserModule,
    BrowserAnimationsModule,
    // Routing
    AppRoutingModule,
    // Internal Module
    AuthModule.forRoot(),
    HeaderModule,
    HomeModule,
    AnalyzeModule,
    AdminModule,
    ErrorModule,
    HiddenProfilesModule,
    MyroadModule,
    ConnectDashboardModule,
    BenchmarkIframeModule,
    SurveyIframeModule,
    ScolaireModule,
    ProfessionnelModule,
    PathwaysModule,
    AnnuaireIframeModule,
    CompanyModule,
    MetierModule,
    ProfileModule,
    NewUserModule,
    UserManagementModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AuthService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
