import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConnectDashboardComponent } from './connect-dashboard.component';

@NgModule({
  declarations: [ConnectDashboardComponent],
  imports: [CommonModule],
  exports: [ConnectDashboardComponent],
})
export class ConnectDashboardModule {}
