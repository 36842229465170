import { Component, OnDestroy } from '@angular/core';
import { AuthService } from '../../../../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent implements OnDestroy {
  private onDestroySubject$ = new Subject<void>();

  public get userName(): string {
    return this.authService.user?.displayName;
  }

  public get currentLang(): string {
    return this.translateService.currentLang;
  }

  constructor(private authService: AuthService, private translateService: TranslateService) {}

  // -- LIFE CYCLE --

  public ngOnDestroy(): void {
    this.onDestroySubject$.next();
    this.onDestroySubject$.complete();
  }

  // -- I18N --

  /**
   * We know that we have just two languages
   */
  public get otherLang(): string {
    const otherLangs: string[] = this.translateService.langs.filter((l) => l !== this.currentLang);

    if (otherLangs.length > 0) return otherLangs[0];

    return null;
  }

  // -- EVENTS --

  public onLogout(): void {
    this.authService.logout();
  }

  public onSwitchLang(): void {
    this.translateService.use(this.otherLang).pipe(takeUntil(this.onDestroySubject$)).subscribe();
  }
}
