import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Route, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HEADER_LOGOS } from '@molecules/header/header-logos.config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [
    `
      .mat-h2 {
        margin: 0;
      }
    `,
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public links: { path: string; label: string; show: boolean }[];
  public readonly images = HEADER_LOGOS;

  private previousRoute: Route[];
  private onDestroySubject$ = new Subject<void>();

  constructor(private router: Router) {}

  // -- LIFE CYCLE --

  public ngOnInit(): void {
    this.links = this.router.config
      .filter((route) => route.data)
      .map(({ path = '', data }) => ({
        path,
        label: data.title,
        show: this.router.url.includes(data.title),
      }));
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.onDestroySubject$)
      )
      .subscribe((event: NavigationEnd) => {
        const url: string = event.url;

        // Root
        if (url === '/' || '') this.previousRoute = null;

        this.previousRoute = this.computePreviousRoute(url);
      });
  }

  public ngOnDestroy(): void {
    this.onDestroySubject$.next();
    this.onDestroySubject$.complete();
  }

  // -- EVENTS --

  public onBack(): void {
    let backUrl = '';
    if (this.previousRoute?.length > 0) backUrl = this.previousRoute.map((t) => t.path).join('/');

    this.router.navigateByUrl('/' + backUrl);
  }

  // -- UTILS --

  public getCurrentRoute(path: string): boolean {
    return this.router.url.includes(path);
  }

  // -- ROUTING --

  /**
   * Compute the previous route from a path
   */
  private computePreviousRoute(path: string): Route[] {
    const temp: Route[] = [];
    const loopFn = function (route: Route, paths: string[]) {
      for (let i = 0; i < route.children.length; i++) {
        const child: Route = route.children[i];
        const containsPath: string = HeaderComponent.contains(child.path, paths);
        if (containsPath != null) {
          paths = HeaderComponent.removePath(child.path, paths);
          temp.push(child);
          if (child.children) {
            loopFn(child, paths);
          }
        }
      }
    };
    loopFn({ children: this.router.config } as Route, HeaderComponent.splitPath(path));

    temp.pop();
    return temp;
  }

  /**
   * Transform a path (/url1/url2) to an array of path [url1, url2]
   */
  private static splitPath(path: string): string[] {
    return path.split('/').filter((s) => s !== '');
  }

  /**
   * Check if the @param paths contains the @param path
   * @param path the path that must be contained
   * @param paths the path that contains
   */
  private static contains(path: string, paths: string[]): string {
    if (!paths || paths.length === 0) return null;

    let count = 0;
    let concatPath = paths[0];
    while (count < paths.length) {
      if (path === concatPath) {
        return concatPath;
      } else {
        count = count + 1;
        concatPath = concatPath + '/' + paths[count];
      }
    }
    return null;
  }

  /**
   * Remove a path from another
   * @param path the path to remove
   * @param paths the path containing what to delete
   */
  private static removePath(path: string, paths: string[]) {
    return paths.filter((p) => !HeaderComponent.splitPath(path).includes(p));
  }
}
