import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ApplicationService } from '@services/application.service';
import { map, Observable } from 'rxjs';
import { poleEmploiApplication } from '../reports/reports.component';

@Component({
  selector: 'app-alumni',
  templateUrl: './alumni.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlumniComponent {
  isPoleEmploiApplication$: Observable<boolean>;

  constructor(private applicationService: ApplicationService) {
    this.isPoleEmploiApplication$ = this.applicationService.application$.pipe(
      map((app) => app.application === poleEmploiApplication)
    );
  }
}
