import { Component } from '@angular/core';
import { IframeComponent } from '@atoms/iframe/iframe.component';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
})
export class UserManagementComponent extends IframeComponent {
  public name() {
    return '/admin/';
  }
}
