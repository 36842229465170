import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  template: `
    <app-header></app-header>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent {
  constructor(private titleService: Title, private router: Router) {
    router.events.subscribe(() => this.setTitle(this.getSolution()));
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  public getSolution() {
    if (this.router.url.includes('analyser')) {
      return 'Analyser';
    }
    if (this.router.url.includes('myroad')) {
      return 'MyRoad';
    }
    if (this.router.url.includes('admin')) {
      return 'Administration';
    }
    return 'Millionroads';
  }
}
