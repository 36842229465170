import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'environments/environment';
import { ApplicationService } from '@services/application.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
})
export class NewUserComponent implements OnInit {
  public link$: Observable<SafeResourceUrl>;

  constructor(
    private applicationService: ApplicationService,
    private oauthService: OAuthService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer
  ) {}

  // -- LIFE CYCLE --

  public ngOnInit(): void {
    this.link$ = this.applicationService.applicationField$.pipe(
      map((applicationField) => this.buildSrc(applicationField)),
      map((src) => this.sanitizer.bypassSecurityTrustResourceUrl(src))
    );
  }

  private buildSrc(applicationField: string): string {
    return (
      environment.iframeBaseUrl +
      '/admin/users/new?application=' +
      applicationField +
      '&token=' +
      this.oauthService.getAccessToken() +
      '&is_simplified=true' +
      '&locale=' +
      this.translateService.currentLang
    );
  }
}
