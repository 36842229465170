<div
  fxFlex
  fxLayoutAlign="center stretch"
  fxLayoutAlign.gt-xs="start stretch"
  fxLayout="row wrap"
  fxLayoutGap="10px grid"
>
  <app-card
    fxFlex="33%"
    fxFlex.lt-sm="80%"
    [icon]="'📖'"
    label="{{ 'analyze.alumni.annuaire.title' | translate }}"
    desc="{{ 'analyze.alumni.annuaire.desc' | translate }}"
    routerLink="annuaire"
  ></app-card>
  <app-card
    fxFlex="33%"
    fxFlex.lt-sm="80%"
    [icon]="'🧮'"
    routerLink="profil"
    label="{{ 'analyze.alumni.cohort.profils.title' | translate }}"
    desc="{{ 'analyze.alumni.cohort.profils.desc' | translate }}"
  ></app-card>
  <a
    *ngIf="(isPoleEmploiApplication$ | async) === false"
    fxFlex="33%"
    fxFlex.xs="80%"
    [href]="'analyze.alumni.cohort.verified.link' | translate"
    target="_blank"
    style="text-decoration: none"
  >
    <app-card
      [icon]="'✔️'"
      label="{{ 'analyze.alumni.cohort.verified.title' | translate }}"
      [knowMore]="true"
      desc="{{ 'analyze.alumni.cohort.verified.desc' | translate }}"
    >
    </app-card>
  </a>
</div>
