import { Component } from '@angular/core';
import { IframeComponent } from '@atoms/iframe/iframe.component';

@Component({
  selector: 'app-connect-dashboard',
  templateUrl: './connect-dashboard.component.html',
})
export class ConnectDashboardComponent extends IframeComponent {
  public name() {
    return '/gps/';
  }
}
