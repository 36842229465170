import { Component } from '@angular/core';
import { IframeComponent } from '@atoms/iframe/iframe.component';

@Component({
  selector: 'app-pathways',
  templateUrl: './pathways.component.html',
})
export class PathwaysComponent extends IframeComponent {
  public name() {
    return '/analyze/';
  }
}
