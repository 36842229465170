import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { PathItemDetailComponent } from '@molecules/path-item-detail/path-item-detail.component';
import { PathItemComponent } from '@molecules/path-item/path-item.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatDividerModule,
    PathItemComponent,
    PathItemDetailComponent,
  ],
  templateUrl: './path-dialog.component.html',
  styleUrls: ['./path-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PathDialogComponent {
  panelOpenState = false;

  constructor(@Inject(MAT_DIALOG_DATA) public path: any) {}
}
