<div
  fxFlex
  fxLayoutAlign="center stretch"
  fxLayoutAlign.gt-xs="start stretch"
  fxLayout="row wrap"
  fxLayoutGap="10px grid"
>
  <app-card
    fxFlex="25%"
    fxFlex.lt-md="50%"
    fxFlex.xs="80%"
    label="{{ 'home.admin.gestion-title' | translate }}"
    desc="{{ 'home.admin.gestion-desc' | translate }}"
    [icon]="'👪'"
    routerLink="gestion"
  >
  </app-card>
  <app-card
    fxFlex="25%"
    fxFlex.lt-md="50%"
    fxFlex.xs="80%"
    label="{{ 'home.admin.new-title' | translate }}"
    desc="{{ 'home.admin.new-desc' | translate }}"
    [icon]="'🐣'"
    routerLink="newUser"
  >
  </app-card>
  <app-card
    fxFlex="25%"
    fxFlex.lt-md="50%"
    fxFlex.xs="80%"
    label="{{ 'home.admin.hidden-title' | translate }}"
    desc="{{ 'home.admin.hidden-desc' | translate }}"
    [icon]="'🦹‍♂️'"
    routerLink="hiddenProfiles"
  >
  </app-card>
</div>
