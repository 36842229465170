<button mat-mini-fab mat-dialog-close class="dialog-close-btn">
  <mat-icon>close</mat-icon>
</button>

<mat-dialog-content>
  <div class="content">
    <ng-container *ngFor="let item of path.data; index as index">
      <app-path-item
        *ngIf="index < 3"
        [size]="index === 0 ? 'display' : 'headline'"
        [label]="index === 0 ? path.label : index === 2 ? item.data[0].label : item.label"
        [iconUrl]="item.iconUrl"
        [noDash]="index === 2"
      >
        <app-path-item-detail [item]="item.data"></app-path-item-detail>
      </app-path-item>
    </ng-container>

    <mat-divider class="mt-16 mb-16"></mat-divider>

    <ng-container *ngIf="path?.data[3] as profil">
      <mat-accordion *ngIf="profil?.data?.length">
        <mat-expansion-panel
          class="mat-elevation-z0"
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <img [src]="profil.iconUrl" />
              <span class="mat-title">{{ profil.label }}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <app-path-item-detail [item]="profil.data"></app-path-item-detail>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
  </div>
</mat-dialog-content>
