import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
})
export class ErrorComponent implements OnInit {
  public code: string;

  constructor(private route: ActivatedRoute) {}

  // -- LIFE CYCLE --

  ngOnInit(): void {
    this.code = this.route.snapshot.paramMap.get('code') || '500';
  }
}
