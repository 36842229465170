import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { Status } from '@models/application/application';
import { ApplicationService } from '@services/application.service';
import { map, mergeMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private applicationService: ApplicationService,
    private router: Router
  ) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const applicationIsRenouvele$ = this.applicationService.application$.pipe(
      map((application) => application?.status !== Status.non_renouvele)
    );

    const unrestrictedPages = ['home'];

    return combineLatest([this.authService.canActivate$, applicationIsRenouvele$]).pipe(
      mergeMap(([canActivate, applicationIsRenouvele]) => {
        if (canActivate) {
          const isManager = this.authService.user.isManager;
          const hasRoles = this.authService.user.hasRoles(next.data.roles);
          const hasGroups = this.authService.user.hasGroups(next.data.groups);

          if (
            isManager ||
            (hasRoles &&
              hasGroups &&
              (applicationIsRenouvele || unrestrictedPages.find((p) => p === next.routeConfig.path)))
          ) {
            return of(true);
          }

          this.router.navigateByUrl(`/error/401`);
          return of(false);
        }

        return this.tryLoginSSO(next).pipe(
          map((ssoLoginSuccessful) => {
            if (ssoLoginSuccessful) return true;

            this.authService.login(state.url);

            // FIXME: Add HTTP status Page error for redirect when non authorized
            return false;
          })
        );
      })
    );
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }

  private tryLoginSSO(routeSnapshot: ActivatedRouteSnapshot): Observable<boolean> {
    const { user, token } = routeSnapshot.queryParams;

    if (user && token) return this.authService.loginFromSSO(user, token);

    return of(false);
  }
}
