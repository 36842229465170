import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './header.component';
import { ProfileMenuModule } from '@molecules/profile-menu/profile-menu.module';
import { ApplicationMenuModule } from '@molecules/application-menu/application-menu.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    // Internal Modules
    ApplicationMenuModule,
    ProfileMenuModule,
    // External Modules
    CommonModule,
    MatIconModule,
    MatTabsModule,
    MatToolbarModule,
    RouterModule,
    TranslateModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
