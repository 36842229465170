<mat-card [ngClass]="{ 'not-owned': !buyed }" class="card mat-elevation-z4" *ngIf="isDisplay">
  <div class="flex">
    <div *ngIf="icon" class="mr-8 ftsize-32 mt-8 mb-16">{{ icon }}</div>
    <div class="space"></div>
    <span *ngIf="isLab" class="bold">🧪 Lab</span>
  </div>
  <div class="flex">
    <mat-card-title class="mat-h1 bold">{{ label }} </mat-card-title>
    <span class="space"></span>
    <a *ngIf="isLab" type="button" class="red no-decoration"> </a>
  </div>
  <div class="crop">
    <img *ngIf="image" [src]="image" />
  </div>
  <div class="center break-word" [ngClass]="imageTitleClass">
    {{ imageTitleKey | translate }}
  </div>
  <mat-card-content *ngIf="desc">
    <p class="mb-16 grey mt-16 desc">
      {{ desc }}
    </p>
  </mat-card-content>
  <span class="space"></span>
  <button mat-flat-button class="bot-0">{{ getButtonText() }}</button>
</mat-card>
