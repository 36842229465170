<ng-container *ngIf="application$ | async as application">
  <ng-container *ngIf="applications$ | async as applications">
    <button mat-button [matMenuTriggerFor]="applicationMenu" color="grey">
      <img *ngIf="application.logo" class="logo" [src]="application.logo" />
      {{ application.name }}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #applicationMenu="matMenu" class="mat-menu-panel-custom">
      <ng-container *ngIf="applications.length > 1">
        <a (click)="onUnselectedApplication()" mat-menu-item>
          <span class="mat-h2">{{ 'all-application' | translate }}</span>
        </a>
        <mat-divider></mat-divider>
      </ng-container>
      <ng-container *ngFor="let app of applications; let first = first">
        <mat-divider *ngIf="!first"></mat-divider>
        <a (click)="onSelectApplication(app)" mat-menu-item>
          <span class="mat-h2">{{ app.name }}</span>
        </a>
      </ng-container>
    </mat-menu>
  </ng-container>
</ng-container>
