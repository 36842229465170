import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule, Routes } from '@angular/router';
import { CardModule } from '@atoms/mat-card/card.module';
import { TranslateModule } from '@ngx-translate/core';
import { AdminComponent } from './admin.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
  },
];

@NgModule({
  declarations: [AdminComponent],
  imports: [
    // Internal Modules
    CardModule,
    // External Modules
    CommonModule,
    FlexLayoutModule,
    RouterModule.forChild(routes),
    TranslateModule,
  ],
  exports: [AdminComponent],
})
export class AdminModule {}
