import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProfessionnelComponent } from './professionnel.component';

@NgModule({
  declarations: [ProfessionnelComponent],
  imports: [CommonModule],
  exports: [ProfessionnelComponent],
})
export class ProfessionnelModule {}
