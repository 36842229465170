import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { PathItemDetailComponent } from '@molecules/path-item-detail/path-item-detail.component';
import { PathItemComponent } from '@molecules/path-item/path-item.component';
import { PathDialogComponent } from '../../pages/path-dialog/path-dialog.component';

@Component({
  selector: 'app-path-card',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatButtonModule, MatDialogModule, PathItemComponent, PathItemDetailComponent],
  templateUrl: './path-card.component.html',
  styleUrls: ['./path-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PathCardComponent {
  @Input() path: any;

  constructor(private dialog: MatDialog) {}

  ordonedFlatData(el: any) {
    const reducer = (acc: any, curr: any) => {
      return [
        ...acc,
        ...[...curr.data].map((e) => {
          const label = curr.label === e.label ? e.label : curr.label + ' : ' + e.label;

          return { ...e, label };
        }),
      ];
    };

    return el.data.reduce(reducer, []).sort((a, b) => b.num - a.num);
  }

  onClick(event: MouseEvent, path: any): void {
    event.stopPropagation();
    this.dialog.open(PathDialogComponent, {
      minWidth: '600px',
      data: path,
      scrollStrategy: new NoopScrollStrategy(),
    });
  }
}
