import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnnuaireIframeComponent } from './annuaireIframe.component';

@NgModule({
  declarations: [AnnuaireIframeComponent],
  imports: [CommonModule],
  exports: [AnnuaireIframeComponent],
})
export class AnnuaireIframeModule {}
