<button mat-button [matMenuTriggerFor]="profileMenu" color="grey">
  <mat-icon>account_circle</mat-icon>
  {{ userName }}
  <mat-icon>arrow_drop_down</mat-icon>
</button>
<mat-menu #profileMenu="matMenu" class="mat-menu-panel-custom">
  <a (click)="onSwitchLang()" mat-menu-item>
    <mat-icon>language</mat-icon>
    <span class="mat-h2">{{ 'language.' + otherLang | translate }}</span>
  </a>
  <mat-divider></mat-divider>
  <a href="https://doc.millionroads.com" target="_blank" mat-menu-item>
    <mat-icon>help</mat-icon>
    <span class="mat-h2"> {{ 'knowledge-base' | translate }} </span>
  </a>
  <mat-divider></mat-divider>
  <a [href]="'mailto:support@millionroads.com'" target="_blank" mat-menu-item>
    <mat-icon>mail</mat-icon>
    <span class="mat-h2">{{ 'contact-support' | translate }}</span>
  </a>
  <mat-divider></mat-divider>
  <a href="https://www.millionroads.com/newsletter" target="_blank" mat-menu-item>
    <mat-icon>view_timeline</mat-icon>
    <span class="mat-h2">{{ 'newsletter' | translate }}</span>
  </a>
  <mat-divider></mat-divider>
  <a (click)="onLogout()" mat-menu-item>
    <mat-icon>exit_to_app</mat-icon>
    <span class="mat-h2">{{ 'logout' | translate }}</span>
  </a>
</mat-menu>
