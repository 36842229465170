import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule, Routes } from '@angular/router';
import { CardModule } from '@atoms/mat-card/card.module';
import { TranslateModule } from '@ngx-translate/core';
import { HomeComponent } from '@pages/home/home.component';
import { SearchModule } from '../../shared/pipes/search/search.module';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
];

@NgModule({
  declarations: [HomeComponent],
  imports: [
    // Internal Modules
    CardModule,
    SearchModule,
    // External Modules
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MatCardModule,
    MatInputModule,
    MatTabsModule,
    RouterModule.forChild(routes),
    TranslateModule,
  ],
  exports: [HomeComponent],
})
export class HomeModule {}
