import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScolaireComponent } from './scolaire.component';

@NgModule({
  declarations: [ScolaireComponent],
  imports: [CommonModule],
  exports: [ScolaireComponent],
})
export class ScolaireModule {}
