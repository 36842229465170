import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule, Routes } from '@angular/router';
import { CardModule } from '@atoms/mat-card/card.module';
import { HroadsClient } from '@clients/hroads/hroads.client';
import { PathCardComponent } from '@molecules/path-card/path-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { AlumniComponent } from './alumni/alumni.component';
import { AnalyzeComponent } from './analyze.component';
import { ObservatoireComponent } from './observatoire/observatoire.component';
import { ReportsComponent } from './reports/reports.component';
import { TypicalPathComponent } from './typical-path/typical-path.component';

const routes: Routes = [
  {
    path: '',
    component: AnalyzeComponent,
  },
];

@NgModule({
  declarations: [AlumniComponent, AnalyzeComponent, ObservatoireComponent, ReportsComponent, TypicalPathComponent],
  imports: [
    // Internal Modules
    CardModule,
    // External Modules
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    RouterModule.forChild(routes),
    TranslateModule,
    PathCardComponent,
  ],
  exports: [AnalyzeComponent],
  providers: [HroadsClient],
})
export class AnalyzeModule {}
