import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HroadsClient } from '@clients/hroads/hroads.client';
import { TrackingClient } from '@clients/tracking/tracking.client';
import { Application } from '@models/application/application';
import { ApplicationService } from '@services/application.service';
import { NullValidationHandler, OAuthEvent, OAuthService, OAuthStorage, UserInfo } from 'angular-oauth2-oidc';
import { IdentityClaims } from 'app/shared/models/identity-claims/identity-claims';
import { User } from 'app/shared/models/user/user';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { authStorageFactory } from './auth-storage';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
  /**
   * Used for authentication guards
   * Waits for the service to finish loading and check that the user is authenticated
   *
   * Waiting for the service loading is required to allow direct access to a protected
   * authenticated. This prevents the following situation:
   * - The user access the protected page: isAuthenticated is false as the initial tryLogin has not
   *    been done
   * - So, the application redirects to login page
   * - Back to the application after login, isAuthenticated is true (token_received event)
   * - The successful login callback redirects to the protected page and removes it from local
   *    storage
   * - Meanwhile, the service is still initializing (restarted when coming back to the application
   *    after login). When it loads the access token, it executes the successful login callback
   * - There is no redirectUri in local storage so it redirects to the home page while the user
   *    wanted to access the protected page
   *
   * Inspired from https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/
   */
  public canActivate$: Observable<boolean> = of(false);
  /**
   * BehaviorSubject containing the authentication state
   * It has to be directly used when the component or service has to be notified when the
   * authentication state changes. Otherwise, use the getter isAuthenticated
   */
  public isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * BehaviorSubject containing the authenticated user
   * It has to be directly used when the component or service has to be notified when the
   * user changes. Otherwise, use the getter user
   */
  public user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  public readonly applications$: Observable<Application[]>;

  private readonly AUTH_STORAGE_ITEMS: Array<string> = [
    'access_token',
    'access_token_stored_at',
    'expires_at',
    'granted_scopes',
    'id_token',
    'id_token_claims_obj',
    'id_token_expires_at',
    'id_token_stored_at',
    'nonce',
    'PKCE_verifier',
    'refresh_token',
    'session_state',
  ];

  private onDestroySubject$: Subject<void> = new Subject<void>();

  /**
   * Indicates if the Authentication Service is fully loaded or not
   */
  private isDoneLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public get isAuthenticated(): boolean {
    return this.isAuthenticated$.value;
  }

  public get user(): User {
    return this.user$.value;
  }

  public get clientId(): string {
    return this.oauthService.clientId;
  }

  public get redirectUri(): string {
    return this.oauthService.redirectUri;
  }

  constructor(
    private applicationService: ApplicationService,
    private hroadsClient: HroadsClient,
    private oauthService: OAuthService,
    private trackingClient: TrackingClient,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    /**
     * Ignore Auth Service initialisation for dispatch domain so there is no nonce/state validation
     * error as the nonce and the state are stored in the application domain local storage and so
     * not shared with dispatch domain
     */
    this.canActivate$ = this.isDoneLoading$.pipe(
      filter((isDoneLoading: boolean) => isDoneLoading),
      map(() => this.isAuthenticated)
    );

    // Listen OAuth events
    this.listenOAuthEvents();
    this.listenSessionEnd();

    // Start automatic silent refresh that refreshes the token before it expires
    this.oauthService.setupAutomaticSilentRefresh();

    // Set list of applications the user has access to
    this.applications$ = this.user$.pipe(
      filter((user) => !!user),
      switchMap((user) => this.managedApplications(user.email)),
      tap((applications) => {
        if (applications && applications.length === 1) this.applicationService.updateApplication(applications[0]);
        else if (!applications?.length) this.document.location.href = 'https://www.millionroads.com/';
      })
    );
  }

  public ngOnDestroy(): void {
    this.onDestroySubject$.next();
    this.onDestroySubject$.complete();
  }

  /**
   * Initialise OAuth module
   */
  public async initAuth(): Promise<void> {
    /**
     * Needed as Keycloak does not return at_hash claim in the access token in Code flow. It
     * prevents the application from crashing.
     * https://ordina-jworks.github.io/security/2019/08/22/Securing-Web-Applications-With-Keycloak.html
     */
    this.oauthService.tokenValidationHandler = new NullValidationHandler();

    /**
     * Load the Keycloak endpoints configuration (discovery document) and initiate login so if user
     * is already logged, the session is loaded.
     */
    await this.oauthService.loadDiscoveryDocumentAndTryLogin();

    if (this.oauthService.hasValidAccessToken()) return this.successfulLogin();

    if (this.oauthService.getRefreshToken()) {
      // If login has failed (i.e. token expired), try to refresh the token if valid
      if (this.hasValidRefreshToken()) {
        try {
          await this.oauthService.refreshToken();
        } catch (err) {
          // Refresh token has failed so it empties all the previous authentication state
          this.emptyAuthStorage();
          this.emptyUser();
          this.isDoneLoading$.next(true);

          return Promise.resolve();
        }

        // Refresh token has succeeded
        return this.successfulLogin();
      }

      /**
       * Access and refresh token are expired. So they are deleted to prevent a white page caused
       * by Adventure service refusing the expired access token.
       */
      this.emptyAuthStorage();
    }

    // Login failed and refresh token is missing
    this.isDoneLoading$.next(true);
    this.emptyUser();

    return Promise.resolve();
  }

  // -- LOGIN --

  public login(returnUrl?: string): void {
    // Remove the initial slash in the path if present
    returnUrl = returnUrl[0] === '/' ? returnUrl.slice(1) : returnUrl;

    localStorage.setItem('returnUrl', returnUrl);
    localStorage.setItem('inProcessLogin', 'true');

    this.oauthService.initCodeFlow();
  }

  public loginFromSSO(user: string, token: string): Observable<boolean> {
    this.oauthService.oidc = false;
    this.oauthService.responseType = '';

    return from(this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(user, token)).pipe(
      tap((userInfo) => userInfo && this.loadUser(userInfo)),
      map((userInfo) => !!userInfo)
    );
  }

  // -- LOGOUT --

  public logout(noRedirectToLogoutUrl = false): void {
    this.emptyUser();
    this.oauthService.logOut(noRedirectToLogoutUrl);
  }

  // -- OAUTH EVENT LISTENERS --

  /**
   * Update authentication status on OAuth event
   */
  private listenOAuthEvents(): void {
    this.oauthService.events.pipe(takeUntil(this.onDestroySubject$)).subscribe(() => {
      this.isAuthenticated$.next(this.oauthService.hasValidAccessToken());
    });
  }

  /**
   * Empty user when session ends
   */
  private listenSessionEnd(): void {
    this.oauthService.events
      .pipe(
        filter((event: OAuthEvent) => event.type === 'session_terminated' || event.type === 'session_error'),
        takeUntil(this.onDestroySubject$)
      )
      .subscribe(() => {
        this.emptyUser();
      });
  }

  private successfulLogin(): void {
    this.loadUser();
    this.trackUser();
  }

  private trackUser(): void {
    if (localStorage.getItem('inProcessLogin')) {
      this.trackingClient
        .actions(this.user)
        .pipe(
          finalize(() => {
            const returnUrl = localStorage.getItem('returnUrl');

            if (returnUrl) {
              localStorage.removeItem('returnUrl');
              this.router.navigate([returnUrl]);
            }
          }),
          takeUntil(this.onDestroySubject$)
        )
        .subscribe();
      localStorage.removeItem('inProcessLogin');
    }
    this.isDoneLoading$.next(true);
  }

  // -- USER --

  private loadUser(userInfo?: UserInfo): void {
    this.user$.next(
      userInfo ? User.fromIdentityClaims(userInfo as unknown as IdentityClaims) : this.loadUserFromIdentityClaims()
    );
  }

  private loadUserFromIdentityClaims(): User {
    return User.fromIdentityClaims(this.oauthService.getIdentityClaims() as IdentityClaims);
  }

  private emptyUser(): void {
    this.user$.next(null);
  }

  /**
   * Remove all the related items in the authentication storage (local storage or session storage
   * according to the configuration)
   */
  private emptyAuthStorage(): void {
    const authStorage: OAuthStorage = authStorageFactory();

    this.AUTH_STORAGE_ITEMS.forEach((item: string) => {
      authStorage.removeItem(item);
    });
  }

  /**
   * Check if the refresh token is present and not expired
   */
  private hasValidRefreshToken(): boolean {
    const refreshToken: string = this.oauthService.getRefreshToken();

    if (!refreshToken) return false;

    const refreshTokenExpiry: number = JSON.parse(atob(refreshToken.split('.')[1])).exp;

    return Math.floor(Date.now() / 1000) < refreshTokenExpiry;
  }

  // -- APPLICATIONS --

  private managedApplications(userEmail: string): Observable<Application[]> {
    return this.hroadsClient.managedApplications(userEmail);
  }
}
