import { Component } from '@angular/core';
import { ApplicationService } from '@services/application.service';
import { map, Observable } from 'rxjs';
import { poleEmploiApplication } from './reports/reports.component';

@Component({
  selector: 'app-analyze',
  templateUrl: './analyze.component.html',
  styleUrls: ['./analyze.component.scss'],
})
export class AnalyzeComponent {
  isPoleEmploiApplication$: Observable<boolean>;

  constructor(private applicationService: ApplicationService) {
    this.isPoleEmploiApplication$ = this.applicationService.application$.pipe(
      map((app) => app.application === poleEmploiApplication)
    );
  }
}
