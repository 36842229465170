import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TypicalPathService } from '@services/typical-path.service';
import { Observable, map } from 'rxjs';

export enum PathType {
  JOB = 'Parcours gagnant accès emploi',
  DIPLOMA = 'Parcours gagnant formation',
  JOB_BEFORE = 'Parcours gagnant emploi avant formation',
}
@Component({
  templateUrl: './typical-path.component.html',
  styleUrls: ['./typical-path.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypicalPathComponent implements OnInit {
  paths$: Observable<any>;
  selectedPaths$: Observable<any>;
  emplois$: Observable<any>;
  emploisBefore$: Observable<any>;
  formations$: Observable<any>;
  public pathType;
  public emploi;
  public formation;
  public emploiBefore;
  public readonly PathType = PathType;

  constructor(private pathService: TypicalPathService) {}

  ngOnInit(): void {
    this.paths$ = this.pathService.paths$;
    this.emplois$ = this.paths$.pipe(map((values) => new Set(values.map((value) => value.label).sort())));
    this.formations$ = this.paths$.pipe(map((values) => new Set(values.map((value) => value.data[1].label).sort())));
    this.emploisBefore$ = this.paths$.pipe(
      map(
        (values) =>
          new Set(
            values
              .map((value) => value.data[2].data[0].data)
              .flat()
              .sort()
          )
      )
    );
  }

  public onSelectPathType(pathType: PathType): void {
    this.pathType = pathType;
    this.emploi = null;
    this.formation = null;
    this.emploiBefore = null;
    this.selectedPaths$ = null;
  }

  public onSelectEmplois(emploi: string): void {
    this.emploi = emploi;
    this.selectedPaths$ = this.paths$.pipe(map((paths) => paths.filter((path) => path.label === emploi)));
  }

  public onSelectFormation(formation: string): void {
    this.formation = formation;
    this.selectedPaths$ = this.paths$.pipe(map((paths) => paths.filter((path) => path.data[1].label === formation)));
  }

  public onSelectEmploisBefore(emploiBefore: string): void {
    this.emploiBefore = emploiBefore;
    this.selectedPaths$ = this.paths$.pipe(
      map((paths) => paths.filter((path) => path.data[2].data[0].data.includes(emploiBefore)))
    );
  }
}
