import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Application } from '@models/application/application';
import { ApplicationService } from '@services/application.service';
import { AuthService } from 'app/auth/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-application-menu',
  templateUrl: './application-menu.component.html',
  styleUrls: ['./application-menu.component.scss'],
})
export class ApplicationMenuComponent implements OnInit {
  public applications$: Observable<Application[]>;

  public application$: Observable<Application>;

  constructor(
    private applicationService: ApplicationService,
    private authService: AuthService,
    private router: Router
  ) {
    this.application$ = this.applicationService.application$;
  }

  // -- LIFE CYCLE --

  public ngOnInit(): void {
    this.applications$ = this.authService.applications$.pipe(
      map((applications) =>
        applications.sort(function (a, b) {
          return a.name?.localeCompare(b.name);
        })
      )
    );
  }

  // -- EVENTS

  public onSelectApplication(application: Application): void {
    this.router.navigateByUrl(`/home`);
    this.applicationService.updateApplication(application);
  }

  public onUnselectedApplication(): void {
    this.applicationService.updateApplication(null);
    this.router.navigateByUrl('/');
  }
}
