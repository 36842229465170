export const environment = {
  production: true,
  realProd: false,
  iframeBaseUrl: 'https://iframe.preprod.mroads.dev',
  keycloak: {
    issuer: 'https://id.preprod.mroads.dev/auth/realms/humanroads',
    redirectUri: 'https://app.preprod.mroads.dev/',
    silentRefreshPage: 'silent-refresh.html',
    clientId: 'analyze-preprod',
    showDebugInformation: false,
  },
};
