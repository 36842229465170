<div
  fxFlex
  fxLayoutAlign="center stretch"
  fxLayoutAlign.gt-xs="start stretch"
  fxLayout="row wrap"
  fxLayoutGap="10px grid"
>
  <app-card
    fxFlex="25%"
    fxFlex.lt-md="50%"
    fxFlex.xs="80%"
    label="{{ 'home.dashboard' | translate }}"
    routerLink="dashboard"
    desc="{{ 'home.myroad.dashboard-desc' | translate }}"
  >
  </app-card>
  <app-card
    *ngIf="applicationField$ | async as applicationField"
    fxFlex="25%"
    fxFlex.lt-md="50%"
    fxFlex.xs="80%"
    label="{{ 'home.myroad.application' | translate }}"
    (click)="connectV2Redirect(applicationField)"
  ></app-card>

  <a
    href="https://doc.millionroads.com/MyRoad-fb1ec84fcaa6405a801cbdf8285a0fad"
    target="_blank"
    fxFlex="25%"
    fxFlex.lt-md="50%"
    fxFlex.xs="80%"
  >
    <app-card label="{{ 'home.myroad.doc-title' | translate }}" desc="{{ 'home.myroad.doc-desc' | translate }}">
    </app-card>
  </a>
</div>
