import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HiddenProfilesComponent } from './hidden-profiles.component';

@NgModule({
  declarations: [HiddenProfilesComponent],
  imports: [CommonModule],
  exports: [HiddenProfilesComponent],
})
export class HiddenProfilesModule {}
