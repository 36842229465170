import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApplicationService } from '@services/application.service';
import { map, tap } from 'rxjs';
import { poleEmploiApplication } from '../reports/reports.component';

@Injectable({ providedIn: 'root' })
export class TypicalPathGuard implements CanActivate {
  constructor(private applicationService: ApplicationService, private router: Router) {}

  canActivate() {
    return this.applicationService.application$.pipe(
      map((app) => app.application === poleEmploiApplication),
      tap((isPoleEmploi) => {
        if (!isPoleEmploi) {
          this.router.navigate(['/home']);
        }
      })
    );
  }
}
