import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export enum PathItemSizeEnum {
  display = 'display',
  headline = 'headline',
  title = 'title',
}

@Component({
  selector: 'app-path-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './path-item.component.html',
  styleUrls: ['./path-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PathItemComponent {
  @Input() size: PathItemSizeEnum = PathItemSizeEnum.title;
  @Input() label: string;
  @Input() iconUrl: string;
  @Input() noDash: boolean;

  readonly sizeEnum = PathItemSizeEnum;
}
