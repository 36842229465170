import { Component } from '@angular/core';
import { IframeComponent } from '@atoms/iframe/iframe.component';

@Component({
  selector: 'app-annuaire',
  templateUrl: './annuaireIframe.component.html',
})
export class AnnuaireIframeComponent extends IframeComponent {
  public name() {
    return '/annuaire/';
  }
}
