import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Application } from '@models/application/application';

const HROADS_URI = '/apis/hroads/api/1';
const USERS_URI = `${HROADS_URI}/users`;

// -- AUTHENTICATION --
const TOKEN_URI = `${HROADS_URI}/token`;
const CLIENT_ID = 'e0a82a50-cc4c-4c28-8e8a-f7ed6c8cadd8';
const CLIENT_SECRET = 'cdb689d47573';
const SCOPE = 'managed_applications';

@Injectable()
export class HroadsClient {
  constructor(private http: HttpClient) {}

  // -- APPLICATIONS --

  public managedApplications(userEmail: string): Observable<Application[]> {
    return this.token().pipe(
      mergeMap((token) => {
        const headers = new HttpHeaders().set('X-Authorization', token.token);

        return this.http.get<Application[]>(`${USERS_URI}/${userEmail}/managed_applications`, { headers });
      })
    );
  }

  // -- AUTHENTICATION --

  private token(): Observable<{ token: string }> {
    const params = new HttpParams().set('client_id', CLIENT_ID).set('client_secret', CLIENT_SECRET).set('scope', SCOPE);

    return this.http.get<{ token: string }>(`${TOKEN_URI}`, { params });
  }
}
