/* eslint-disable */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  public transform(items: any[], keys: string, searchText: string): any[] {
    if (!items) return [];

    if (!searchText) return items;

    return (items || []).filter((item) =>
      keys
        .split(',')
        .some(
          (key) =>
            item.hasOwnProperty(key) &&
            !!item[key] &&
            this.slugify(item[key].toLowerCase()).includes(this.slugify(searchText.toLowerCase()))
        )
    );
  }

  private slugify(text: string): string {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  }
}
