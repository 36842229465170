<div
  fxFlex
  fxLayoutAlign="center stretch"
  fxLayoutAlign.gt-xs="start stretch"
  fxLayout="row wrap"
  fxLayoutGap="10px grid"
>
  <app-card
    fxFlex="25%"
    fxFlex.lt-md="50%"
    fxFlex.xs="80%"
    label="{{ 'analyze.reports.title' | translate }}"
    [icon]="'🧮'"
    desc="{{ 'analyze.reports.desc' | translate }}"
    routerLink="reports"
  >
  </app-card>
  <app-card
    *ngIf="(isPoleEmploiApplication$ | async) === false"
    fxFlex="25%"
    fxFlex.lt-md="50%"
    fxFlex.xs="80%"
    label="{{ 'analyze.alumni.title' | translate }}"
    [icon]="'🗃️'"
    desc="{{ 'analyze.alumni.desc' | translate }}"
    routerLink="alumni"
  >
  </app-card>
  <ng-container *ngIf="(isPoleEmploiApplication$ | async) === false">
    <app-card
      fxFlex="25%"
      fxFlex.lt-md="50%"
      fxFlex.xs="80%"
      label="{{ 'analyze.observatoire.title' | translate }}"
      [icon]="'🔭'"
      [isLab]="true"
      desc="{{ 'analyze.observatoire.desc' | translate }}"
      routerLink="observatoire"
    >
    </app-card>
    <a
      class="analyze__crm"
      fxFlex="25%"
      fxFlex.lt-md="50%"
      fxFlex.xs="80%"
      href="{{ 'analyze.crm.url' | translate }}"
      target="_blank"
    >
      <app-card
        [icon]="'🔁'"
        label="{{ 'analyze.crm.title' | translate }}"
        [knowMore]="true"
        desc="{{ 'analyze.crm.desc' | translate }}"
      >
      </app-card
    ></a>
    <a
      fxFlex="25%"
      fxFlex.lt-md="50%"
      fxFlex.xs="80%"
      href="mailto:product@millionroads.com"
      target="_blank"
      style="text-decoration: none"
    >
      <app-card
        label="{{ 'analyze.lab.title' | translate }}"
        [icon]="'🧪'"
        [contact]="true"
        desc="{{ 'analyze.lab.desc' | translate }}"
      >
      </app-card
    ></a>
    <a
      class="analyze__enquete"
      fxFlex="25%"
      fxFlex.lt-md="50%"
      fxFlex.xs="80%"
      href="{{ 'analyze.survey.url' | translate }}"
      target="_blank"
    >
      <app-card
        label="{{ 'analyze.survey.title' | translate }}"
        [icon]="'🧲'"
        [knowMore]="true"
        desc="{{ 'analyze.survey.desc' | translate }}"
      >
      </app-card
    ></a>
  </ng-container>
</div>
