import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { authStorageFactory } from './auth-storage';
import { authConfig } from './auth.config';
import { oAuthModuleConfig } from './oauth-module.config';

@NgModule({
  imports: [HttpClientModule, OAuthModule.forRoot()],
})
export class AuthModule {
  public static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: AuthConfig, useValue: authConfig },
        { provide: OAuthModuleConfig, useValue: oAuthModuleConfig },
        { provide: OAuthStorage, useFactory: authStorageFactory },
      ],
    };
  }
}
