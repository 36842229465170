import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { CardModule } from '@atoms/mat-card/card.module';
import { TranslateModule } from '@ngx-translate/core';
import { MyroadComponent } from './myroad.component';

@NgModule({
  declarations: [MyroadComponent],
  imports: [CommonModule, CardModule, TranslateModule, FlexLayoutModule, RouterModule],
  exports: [MyroadComponent],
})
export class MyroadModule {}
