<div
  fxFlex
  fxLayoutAlign="center stretch"
  fxLayoutAlign.gt-xs="start stretch"
  fxLayout="row wrap"
  fxLayoutGap="10px grid"
>
  <app-card
    fxFlex="33%"
    fxFlex.lt-sm="80%"
    [icon]="'🏢'"
    routerLink="metier"
    label="{{ 'analyze.observatoire.job.title' | translate }}"
    [isLab]="true"
    desc="{{ 'analyze.observatoire.job.desc' | translate }}"
  >
  </app-card>
  <app-card
    fxFlex="33%"
    fxFlex.lt-sm="80%"
    [icon]="'🏫'"
    routerLink="company"
    label="{{ 'analyze.observatoire.company.title' | translate }}"
    [isLab]="true"
    desc="{{ 'analyze.observatoire.company.desc' | translate }}"
  ></app-card>
</div>
