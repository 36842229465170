export interface Application {
  id: string;
  application: string;
  name: string;
  logo: string;
  status: Status;
}

export enum Status { // Export enum shoudn't be necessary
  en_cours = 'en_cours',
  livre = 'livre',
  demo = 'demo',
  renouvellement = 'renouvellement',
  non_renouvele = 'non_renouvele',
  backlog = 'backlog',
}
