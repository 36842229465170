/**
 * An authenticated user role
 */
export enum Role {
  SuperAdmin = 'superadmin',
  ContentManager = 'content_manager',
  ProjectManager = 'project_manager',
  AppAdmin = 'app_admin',
  AppManager = 'app_manager',
}
