import { Group } from 'app/shared/models/group/group';
import { IdentityClaims } from 'app/shared/models/identity-claims/identity-claims';
import { Md5 } from 'ts-md5';
import { Role } from '../role/role';
import { ApiUser } from './user.api';

/**
 * A user
 */
export class User {
  private _picture: string;

  /**
   * The user profile picture
   */
  public get picture(): string {
    if (!this._picture) {
      const hash = Md5.hashStr(this.email.trim().toLowerCase());

      this._picture = `https://www.gravatar.com/avatar/${hash}?d=https%3A%2F%2Feu.ui-avatars.com%2Fapi%2F%3Fname%3D${this.email}`;
    }

    return this._picture;
  }

  public get displayName(): string {
    if (this.firstName || this.lastName) return this.firstName + ' ' + this.lastName;

    return this.email;
  }

  /**
   * A user
   *
   * @param uuid The user uuid
   * @param email The user email
   * @param groups The user and applications groups. It is empty if the user and applications has no affected role (i.e. end user)
   * @param roles The user roles. It is empty if the user has no affected role (i.e. end user)
   * @param apps The user authorized applications. It is empty if the user roles are not related to
   *    a specific application
   * @param firstName The user first name
   * @param lastName The user last name
   * @param phone The user phone number
   */
  constructor(
    public uuid: string,
    public email: string,
    public firstName: string,
    public lastName: string,
    public phone: string,
    public groups: Array<Group>,
    public roles: Array<Role>,
    public apps: Array<string>
  ) {}

  public static fromIdentityClaims(claims: IdentityClaims): User {
    if (!claims || !claims.email) return null;

    return new User(
      claims.uuid,
      claims.email,
      claims.firstName ? claims.firstName : '',
      claims.lastName ? claims.lastName : '',
      claims.phone ? claims.phone : '',
      claims.groups ? (claims.groups as Array<Group>) : [],
      claims.roles ? (claims.roles as Array<Role>) : [],
      claims.apps ? claims.apps : []
    );
  }

  /**
   * Build the User object from the Api User object
   *
   * @param apiUser The Api User object
   * @returns The new User object
   */
  public static build(apiUser: ApiUser): User {
    return new User(apiUser.uuid, apiUser.email, apiUser.firstname, apiUser.lastname, apiUser.phone, [], [], []);
  }

  /**
   * Get the Api User object from the User object
   *
   * @returns The corresponding Api User object
   */
  public toApi(): ApiUser {
    const apiUser: ApiUser = { uuid: this.uuid, email: this.email };

    if (this.firstName) apiUser.firstname = this.firstName;

    if (this.lastName) apiUser.lastname = this.lastName;

    if (this.phone) apiUser.phone = this.phone;

    return apiUser;
  }

  // -- ROLES --

  public get isManager(): boolean {
    return (
      this.roles.includes(Role.SuperAdmin) ||
      this.roles.includes(Role.ProjectManager) ||
      this.roles.includes(Role.ContentManager)
    );
  }

  public hasRoles(roles: Role[]): boolean {
    if (!roles) return true;

    for (const role of roles) {
      if (!!this.roles && this.roles.includes(role)) return true;
    }

    return false;
  }

  // -- GROUPS --

  public hasGroups(groups: Group[]): boolean {
    if (!groups) return true;

    for (const group of groups) {
      if (!!this.groups && this.groups.includes(group)) return true;
    }

    return false;
  }
}
