import { Injectable } from '@angular/core';
import { Application } from '@models/application/application';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

const APPLICATION_LOCAL_STORAGE = 'application';

@Injectable({ providedIn: 'root' })
export class ApplicationService {
  public readonly application$: Observable<Application>;
  private readonly _application$: BehaviorSubject<Application> = new BehaviorSubject(
    JSON.parse(localStorage.getItem(APPLICATION_LOCAL_STORAGE))
  ); // Session Storage

  /**
   * Return the application field of the selected application
   */
  public get applicationField$(): Observable<string> {
    return this._application$.asObservable().pipe(
      map((app) => app?.application),
      filter((application) => application != null)
    );
  }

  /**
   * Update the selected application
   * Use the local storage to store it
   */
  public updateApplication(application: Application) {
    localStorage.setItem(APPLICATION_LOCAL_STORAGE, JSON.stringify(application));
    this._application$.next(application);
  }

  constructor() {
    this.application$ = this._application$.asObservable();
  }
}
