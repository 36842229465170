/**
 * An authenticated user group
 */
export enum Group {
  Analyser = 'module_analyze',
  Annuaire = 'module_annuaire',
  Benchmark = 'module_benchmark',
  Survey = 'module_survey',
  MyRoad = 'module_gps',
}
