import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PathwaysComponent } from './pathways.component';

@NgModule({
  declarations: [PathwaysComponent],
  imports: [CommonModule],
  exports: [PathwaysComponent],
})
export class PathwaysModule {}
